.tab
	display inline-flex
	flex-wrap wrap
	width 100%

	input
		display none

	&-yes,
	&-no
		&:checked
			+ .tab__title
				position relative
				top 0
				z-index 1
				margin-top 0
				height 150px
				border 0
				background $white
				box-shadow 0px 4px 8px alpha($black, .25)

				@media (max-width $grid-md)
					margin-top 0
					height 90px

	&-yes
		&:checked
			~ .tab__content-yes
				position relative
				z-index 2
				display block
				opacity 1

	&-no
		&:checked
			~ .tab__content-no
				position relative
				z-index 2
				display block
				opacity 1

	&__title
		z-index 1
		display inline-flex
		align-items center
		margin-top 5px
		margin-bottom 0
		padding-left 80px
		width 50%
		height 145px
		border 1px solid $gray-400
		border-bottom none
		border-top-left-radius 15px
		border-top-right-radius 15px
		background $gray-200
		color $black
		font-weight bold
		font-size 3.2rem
		line-height (37 / 32)

		@media (max-width $grid-md)
			flex-direction column
			justify-content center
			margin-top 2px
			padding-left 0
			height 88px
			text-align center
			font-size 1.7rem
			line-height 1

	&__content
		position absolute
		z-index -1
		display none
		display flex
		flex-wrap wrap
		padding 60px 90px 25px
		width 100%
		background-color $white
		box-shadow 0px 4px 8px alpha($black, .25)
		opacity 0

		@media (max-width $grid-lg)
			padding 30px 35px 0

		&:before
			position absolute
			top -5px
			left 0
			width 50%
			height 10px
			background-color $white
			content ''

		&-no
			&:before
				right 0
				left auto

	&__icon
		width 100px
		height 70px
		background-position center
		background-size 100%
		background-repeat no-repeat

		@media (max-width $grid-md)
			width 45px
			height 30px

		&-yes
			margin-right 4.5rem
			background-image url('../images/tab/tab-yes.svg')

			@media (max-width $grid-md)
				margin-right 0

		&-no
			margin-right 3rem
			background-image url('../images/tab/tab-no.svg')

			@media (max-width $grid-md)
				margin-right 0

	&__item
		position relative
		margin-bottom 20px
		padding-left 45px
		font-size 2.3rem
		line-height (27 / 23)

		@media (max-width $grid-md)
			padding-left 15px
			font-size 1.8rem
			line-height (20 / 18)

		&:before
			position absolute
			top 50%
			left 0
			width 30px
			height 20px
			background-position center
			background-size 30px 20px
			background-repeat no-repeat
			content ''
			transform translateY(-50%)

		&-yes
			margin-bottom 35px
			padding-left 60px

			@media (max-width $grid-lg)
				margin-bottom 30px
				padding-left 45px

			&:before
				background-image url('../images/profit/profit__check.svg')

		&-no
			margin-bottom 25px

			@media (max-width $grid-lg)
				margin-bottom 10px
				padding-right 10px
				padding-bottom 5px

			&:before
				width 20px
				background-image url('../images/profit/profit__no.svg')
				background-size 20px 20px

				@media (max-width $grid-md)
					top 5px
					left -20px
					transform none
