.main
	position relative
	padding-top (490 * 100 / 1660)%
	background-position center
	background-size cover

	@media (max-width $grid-md)
		padding-top (305 * 100 / 360)%

	&__content
		position absolute
		top 50%
		right 0
		left 0
		transform translateY(-50%)

		@media (max-width $grid-md)
			right 30px
			left 30px

	&__title
		font-weight bold
		font-size 6.3rem
		line-height (74 / 63)

		@media (max-width $grid-md)
			font-size 3.2rem
			line-height (37 / 30)

		&-about
			margin-top 130px

			@media (max-width $grid-xl)
				margin-top 70px

			@media (max-width $grid-md)
				margin-top 20px

		&-h5
			text-transform uppercase
			font-weight 500
			font-size 2.6rem
			line-height (30 / 26)

			@media (max-width $grid-md)
				font-size 1.6rem
				line-height (25 / 16)