.filter
	margin-top -50px
	padding 45px 95px 90px
	border-radius 15px
	background-color $white
	box-shadow 0px 4px 15px alpha($black, .15)

	@media (max-width $grid-lg)
		padding 45px 40px 50px

	@media (max-width $grid-md)
		flex-wrap wrap
		margin-top -10px
		margin-right -15px
		margin-left -15px
		padding 30px 15px 40px

	&__title
		font-weight bold
		font-size 2.6rem
		line-height (30 / 26)

		@media (max-width $grid-md)
			font-size 2.2rem

	&__left
		flex-shrink 0
		width 60%

		@media (max-width $grid-md)
			width 100%

	&__right
		flex-shrink 0
		width 40%

		@media (max-width $grid-md)
			width 100%

	&__checkbox
		display grid
		grid-template-columns max-content max-content
		grid-gap 20px 55px

		@media (max-width $grid-xl)
			display block

	.btn
		@media (max-width $grid-md)
			height 55px
			font-size 1.9rem

			&:after
				width 15px
				height 15px