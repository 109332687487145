.card
	position relative
	border 0
	background-color transparent

	&__photo
		position relative

	&__play
		position absolute
		top 50%
		left 50%
		width 85px
		height 85px
		border-radius 50%
		background-color $primary
		transform translate(-50%, -50%)

		@media (max-width $grid-md)
			top 58%
			width 65px
			height 65px

		&:hover
			opacity .8

		&:after
			position absolute
			top 50%
			left 54%
			width 0
			height 0
			outline none
			border-width 1rem 0 1rem 2rem
			border-style solid
			border-color transparent transparent transparent $white
			content ''
			transform translate(-50%, -50%)

			@media (max-width $grid-md)
				border-width 7px 0 7px 12px

	&-info
		align-items center

		@media (max-width $grid-lg)
			margin-bottom 60px

		.card__title
			text-align center
			font-weight bold
			font-size 2.7rem
			line-height (32 / 27)

			@media (max-width $grid-md)
				font-size 2.5rem
				line-height (29 / 25)

				br
					display none

		.card__photo
			position relative
			z-index 1
			width 80px
			height 80px
			background-position center
			background-size 100% 100%
			background-repeat no-repeat

		&:before
			position absolute
			top -25px
			left 50%
			z-index 0
			width 60px
			height 60px
			border-radius 50%
			background-color $white
			content ''

			@media (max-width $grid-md)
				top -20px

		.content
			@media (max-width $grid-xl)
				br
					display none

	&-number
		align-items center

		@media (max-width $grid-md)
			margin-bottom 45px

		.card__photo
			width 80px
			height 75px
			background-position center
			background-size 100% 100%
			background-repeat no-repeat

		.card__title
			font-weight bold
			font-size 3.2rem
			line-height (37 / 32)

			@media (max-width $grid-md)
				font-size 2.5rem
				line-height (29 / 25)

		.content
			@media (max-width $grid-xl)
				br
					display none

	&-border
		height 100%

		&:before
			position absolute
			top 10px
			left 50%
			width 1px
			height 95%
			background-color #C4C4C4
			content ''
			transform translateX(-50%)

	&-client
		.card__photo
			width 140px
			height 140px
			border 6px solid $primary
			border-radius 50%
			background-position center
			background-size 120%

		.card__title
			font-weight bold
			font-size 2.7rem
			line-height (32 / 27)

			@media (max-width $grid-md)
				font-size 2.2rem
				line-height (26 / 22)

		.card__icon
			height 70px
			background-position center
			background-size 100%
			background-repeat no-repeat

			@media (max-width $grid-md)
				height 50px

			&-credit
				width 100px
				background-image url('../images/card/card-credit.svg')

				@media (max-width $grid-md)
					width 70px

			&-cause
				width 85px
				background-image url('../images/card/card-cause.svg')

				@media (max-width $grid-md)
					width 65px
					height 55px

		.card__border
			border-right 1px solid #C4C4C4

			@media (max-width $grid-md)
				border 0

		.content
			@media (max-width $grid-md)
				br
					display none

	&-solution
		margin-top -17rem
		padding 6rem 8rem 4rem 5rem
		width 29%
		border-radius 15px
		background-color $white
		box-shadow 0px 4px 15px alpha($black, .15)

		@media (max-width $grid-xl)
			padding 6rem 4rem 4rem 4rem

		@media (max-width $grid-lg)
			padding 6rem 2rem 4rem
			width 48%

			&:nth-child(3)
				margin-top 2rem

		@media (max-width $grid-md)
			margin-top -90px
			margin-bottom 135px
			padding 5rem 3rem 3.5rem 3rem
			width 100%

			&:nth-child(3)
				margin-top -90px

		&:before
			position absolute
			top 40px
			right 30px
			width 100px
			height 100px
			border-radius 50%
			background-color #E4E1DA
			background-image url('../images/card/card-check.svg')
			background-position center
			background-size 35px 25px
			background-repeat no-repeat
			content ''

			@media (max-width $grid-xl)
				top 20px
				right 20px
				width 80px
				height 80px

			@media (max-width $grid-md)
				top 30px

		&:last-child
			@media (max-width $grid-md)
				margin-bottom 75px

		.card__number
			margin-bottom 4.5rem
			font-weight bold
			font-size 5rem
			line-height (59 / 50)

			@media (max-width $grid-md)
				margin-bottom 40px

		.card__title
			color $primary
			text-transform uppercase
			font-weight 500
			font-size 2.3rem
			line-height (27 / 23)

			@media (max-width $grid-md)
				font-size 2rem
				line-height (23 / 20)

		.card__item
			position relative
			padding-left 3.5rem

			@media (max-width $grid-md)
				padding-left 40px

			&:before
				position absolute
				top 0
				left 0
				width 2.5rem
				height 2.5rem
				background-position center
				background-size auto 100%
				background-repeat no-repeat
				content ''

				@media (max-width $grid-md)
					left 5px

			&-region
				&:before
					top -5px
					height 3rem
					background-image url('../images/card/card-region.svg')

			&-summ
				&:before
					background-image url('../images/card/card-summ.svg')

			&-date
				@media (max-width $grid-md)
					margin-bottom 25px

				&:before
					background-image url('../images/card/card-date.svg')

		.btn
			width 100%
			height 5.5rem
			font-size 1.9rem

			@media (max-width $grid-md)
				width 215px
				height 45px
				font-size 1.6rem

		.content
			@media (max-width $grid-md)
				font-size 1.8rem
				line-height (27 / 18)

	&-bank
		align-items center
		padding 45px 45px 30px 45px
		height 100%
		background-color $white

		@media (max-width $grid-xl)
			padding 45px 30px 30px

		@media (max-width $grid-lg)
			margin-bottom 30px
			padding 20px 20px 30px
			height max-content

		.card__photo
			width 145px
			height 130px
			background-position center
			background-size 100% 100%
			background-repeat no-repeat

			@media (max-width $grid-md)
				width 105px
				height 105px

	&-blog
		height 100%
		border .5px solid #828282
		color $black

		@media (max-width $grid-xl)
			height max-content

		&:hover
			color $black

		.card__photo
			position relative
			padding-top (400 * 100 / 670)%
			background-size cover

			@media (max-width $grid-md)
				padding-top (245 * 100 / 335)%

		.card__content
			padding 20px 40px 40px 40px

			@media (max-width $grid-xl)
				padding 20px 20px 40px

			@media (max-width $grid-md)
				padding 15px 20px 25px 20px

		.card__type
			display flex
			justify-content center
			width 165px
			background-color $primary
			color $white
			text-transform uppercase
			font-size 1.9rem
			line-height (36 / 19)

			@media (max-width $grid-xl)
				width 115px

			@media (max-width $grid-md)
				width 75px
				font-size 1.2rem
				line-height (24 / 12)

		.card__info
			position relative
			white-space nowrap
			font-weight 300
			font-size 1.5rem
			line-height (20 / 15)

			@media (max-width $grid-md)
				font-size 1rem
				line-height (20 / 10)

			&:before
				position absolute
				top 50%
				left -25px
				background-size 100% 100%
				background-repeat no-repeat
				content ''
				transform translateY(-50%)

				@media (max-width $grid-md)
					top 47%
					left -13px

			&-view
				margin-right 60px

				@media (max-width $grid-xl)
					margin-right 45px

				@media (max-width $grid-md)
					margin-right 25px

				&:before
					left -30px
					width 25px
					height 12px
					background-image url('../images/card/card-view.svg')

					@media (max-width $grid-md)
						left -18px
						width 18px
						height 8px

			&-date
				&:before
					width 16px
					height 16px
					background-image url('../images/card/card-data.svg')

					@media (max-width $grid-md)
						width 11px
						height 12px

		.content
			line-height (31 / 20)

			@media (max-width $grid-md)
				font-size 1.6rem
				line-height (20 / 16)

		.card__watch
			position relative
			display flex
			justify-content center
			align-items center
			width 225px
			height 35px
			background-color $primary
			color $white
			text-transform uppercase
			font-size 1.9rem

			@media (max-width $grid-md)
				width 155px
				height 25px
				font-size 1.5rem

			&:before
				margin-right 15px
				width 16px
				height 16px
				background-image url('../images/card/card-watch.svg')
				background-size 100% 100%
				content ''

				@media (max-width $grid-md)
					margin-right 10px
					width 12px
					height 12px

	&-article
		border .5px solid #828282
		color $black

		&:hover
			color $black

		.card__photo
			padding-top (285 * 100 / 400)%
			background-size cover

		.card__type
			display flex
			justify-content center
			max-width 100%
			width 165px
			background-color $primary
			color $white
			text-transform uppercase
			font-size 1.9rem
			line-height (36 / 19)

			@media (max-width $grid-md)
				width 75px
				font-size 1.2rem
				line-height (24 / 12)

		.card__info
			position relative
			white-space nowrap
			font-weight 300
			font-size 1.5rem
			line-height (20 / 15)

			@media (max-width $grid-md)
				font-size 1rem
				line-height (20 / 10)

			&:before
				position absolute
				top 50%
				left -25px
				background-size 100% 100%
				background-repeat no-repeat
				content ''
				transform translateY(-50%)

				@media (max-width $grid-xl)
					left -30px

				@media (max-width $grid-md)
					top 47%
					left -13px

			&-view
				margin-right 55px
				margin-left 35px

				@media (max-width $grid-md)
					margin-right 25px
					margin-left 0

				&:before
					left -35px
					width 25px
					height 12px
					background-image url('../images/card/card-view.svg')

					@media (max-width $grid-md)
						left -18px
						width 18px
						height 8px

			&-date
				@media (max-width $grid-xl)
					margin-left 35px

				@media (max-width $grid-md)
					margin-right 0

				&:before
					width 16px
					height 16px
					background-image url('../images/card/card-data.svg')

					@media (max-width $grid-md)
						width 11px
						height 12px

		.card__content
			padding 20px 20px 15px 20px

			@media (max-width $grid-md)
				padding 15px 15px 30px

		.content
			@media (max-width $grid-md)
				font-size 1.6rem
				line-height (20 / 16)

	&-worker
		align-items center
		margin-bottom 45px

		@media (max-width $grid-md)
			margin-bottom 20px

		.card__photo
			width 210px
			height 210px
			border-radius 50%
			background-size 100%
			background-repeat no-repeat

			@media (max-width $grid-xl)
				width 180px
				height 180px

			@media (max-width $grid-lg)
				width 130px
				height 130px

			@media (max-width $grid-md)
				width 105px
				height 105px

		.title
			@media (max-width $grid-md)
				font-size 1.5rem
				line-height (18 / 15)

		.content
			@media (max-width $grid-md)
				font-size 1.2rem
				line-height (27 / 12)

	&-example
		padding 35px 75px 60px 60px
		border-radius 15px
		box-shadow 0px 4px 15px alpha($black, .15)

		@media (max-width $grid-xl)
			padding 35px 40px 60px 40px

		@media (max-width $grid-md)
			padding 10px 20px 25px

		.card__photo
			position relative
			flex-shrink 0
			width 135px
			height 135px
			border 6px solid $primary
			border-radius 50%
			background-position center
			background-size 100%
			background-repeat no-repeat

			@media (max-width $grid-xl)
				width 100px
				height 100px

			@media (max-width $grid-md)
				width 105px
				height 105px

			&:before
				position absolute
				right -15px
				bottom -15px
				width 55px
				height 55px
				border-radius 50%
				background-color #E4E1DA
				background-image url('../images/card/card-check.svg')
				background-position center
				background-size 18px 13px
				background-repeat no-repeat
				content ''

				@media (max-width $grid-xl)
					width 45px
					height 45px

				@media (max-width $grid-md)
					right -10px
					bottom -10px
					width 40px
					height 40px
					background-size 14px 10px

		.card__title
			color $primary
			text-transform uppercase
			font-weight 500
			font-size 2.3rem
			line-height (27 / 23)

			@media (max-width $grid-md)
				font-size 2rem
				line-height (23 / 20)

		.card__item
			position relative
			padding-left 3.5rem

			@media (max-width $grid-md)
				padding-left 40px

			&:before
				position absolute
				top 0
				left 0
				width 2.5rem
				height 2.5rem
				background-position center
				background-size auto 100%
				background-repeat no-repeat
				content ''

				@media (max-width $grid-md)
					left 5px

			&-region
				&:before
					top -5px
					height 3rem
					background-image url('../images/card/card-region.svg')

			&-summ
				&:before
					background-image url('../images/card/card-summ.svg')

		.btn
			width 270px
			height 55px
			font-size 1.9rem

			@media (max-width $grid-md)
				height 45px
				font-size 1.8rem

		.content
			@media (max-width $grid-md)
				font-size 1.8rem
				line-height (27 / 18)
