.background
	&-gray
		background-color $gray-200

	&-before
		position relative
		background-position center
		background-size cover

		&:before
			position absolute
			top 0
			right 0
			bottom 0
			left 0
			z-index 0
			background-color alpha($gray-800, .65)
			content ''

	&-dark
		background-color $gray-800
		background-image url('../images/background/background-dark.svg')
		background-size cover

	&-pay
		@media (max-width $grid-lg)
			margin-top 0 !important
			padding-top 0 !important
			background-color $white
			background-image none