.slider
	margin-top -105px

	@media (max-width $grid-lg)
		margin-top 0

	@media (max-width $grid-md)
		margin-top 60px

	&__wrapper
		position relative
		margin-top -30px
		padding 25px 100px 25px 100px
		border-radius 15px
		background-color $white
		box-shadow 0px 4px 15px alpha($black, .15)

		@media (max-width $grid-md)
			padding 30px

	&__arrow
		position absolute
		top 0
		bottom 0
		z-index 1
		display block
		width 25px
		height 35px
		height 100%
		background-image url('../images/slider/slider__arrow.svg')
		background-position center
		background-size 100% auto
		background-repeat no-repeat
		@extend .transition

		&:hover
			opacity .8

		@media (max-width $grid-md)
			top 90px
			background-position top

		&-left
			left 70px

			@media (max-width $grid-md)
				left 20px

		&-right
			right 70px
			transform scaleX(-1)

			@media (max-width $grid-md)
				right 20px
