.header
	position relative
	z-index 100
	background-color $white
	box-shadow 0px 4px 15px alpha($black, .15)

	@media (max-width $grid-lg)
		position fixed
		top 0
		right 0
		left 0

	&__logo
		display block
		flex-shrink 0
		width 25.5rem
		height 8rem
		background-image url('../images/header/header__logo.svg')
		background-position 50%
		background-size 100%
		background-repeat no-repeat

		@media (max-width $grid-lg)
			width 160px
			height 60px

	&__link
		display flex
		align-items center
		padding 0 3rem
		height 10rem
		color $black
		text-transform uppercase
		white-space nowrap
		font-weight bold
		font-size 1.5rem
		line-height (18 / 15)

		@media (max-width $grid-xl)
			padding 0 1.5rem

		@media (max-width $grid-lg)
			padding 0
			padding-top 15px
			width 100%
			height 55px
			border-bottom 1px solid alpha(#D6D6D6, .7)
			font-size 2.2rem
			line-height (26 / 22)

		&:hover
			color lighten($black, 20%)

		&-active
			@media (max-width $grid-lg)
				border-bottom 2px solid $primary

	&__btn
		position relative
		display flex
		flex-shrink 0
		flex-direction column
		justify-content center
		padding-left 8rem
		width 26rem
		height 10rem
		background-color $primary
		color $white
		text-transform uppercase
		font-weight 500
		font-size 1.5rem
		line-height (18 / 15)
		@extend .transition

		@media (max-width $grid-lg)
			margin-top 40px
			margin-right -15px
			margin-left -15px
			padding-left 100px
			width calc(100% + 30px)
			font-size 1.7rem
			line-height (20 / 17)

		&:hover
			background-color $primaryHov
			color $white

		span
			display block
			margin-bottom 5px
			font-weight bold
			font-size 2rem
			line-height (23 / 20)

			@media (max-width $grid-lg)
				font-size 2.2rem
				line-height (26 / 22)

		&:before
			position absolute
			top 50%
			left 2.5rem
			width 4rem
			height 4rem
			background-image url('../images/header/header__phone.svg')
			background-position center
			background-size 100%
			background-repeat no-repeat
			content ''
			transform translateY(-50%)

			@media (max-width $grid-lg)
				left 35px

		&-footer
			padding-left 12.5rem
			width 35.5rem
			height 11rem

			@media (max-width $grid-lg)
				margin-top 0
				padding-left 50px
				max-width 100%

			&:before
				left 5rem
				width 5rem
				height 5rem

				@media (max-width $grid-lg)
					left 15px
					width 3rem
					height 3rem
