.custom
	&-select
		padding-left 20px
		border .5px solid $gray-600
		background-image url('../images/custom/custom-select.svg')
		background-position 90% 50%
		background-size 20px 12px
		font-size 2.5rem
		font-family inherit
		line-height (29 / 25)
		appearance none

		@media (max-width $grid-md)
			height 45px
			font-size 1.8rem
			line-height (21 / 18)