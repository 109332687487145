.media
	position relative
	padding-top (675 * 100 / 1380)%
	background-position top
	background-size cover
	color $white

	@media (max-width $grid-md)
		padding-top (500 * 100 / 330)%

	&:before
		position absolute
		top 0
		right 0
		bottom 0
		left 0
		z-index 0
		background linear-gradient(0deg, rgba(41, 41, 41, .91) 17.1%, rgba(0, 0, 0, 0) 82.31%)
		content ''
		opacity .9

	&__description
		position absolute
		bottom 50px
		left 140px
		z-index 2

		@media (max-width $grid-xl)
			left 70px

		@media (max-width $grid-md)
			bottom 25px
			left 20px

	&__type
		text-transform uppercase
		letter-spacing .2em
		font-weight 500
		font-size 2.7rem
		line-height (32 / 27)

		@media (max-width $grid-md)
			font-size 1.8rem
			line-height (21 / 18)

	&__title
		width 85%
		font-weight bold
		font-size 5rem
		line-height (59 / 50)

		@media (max-width $grid-md)
			width 100%
			font-size 3rem
			line-height (35 / 30)

	&__link
		position relative
		color $white
		font-weight 500
		font-size 2.7rem
		line-height (32 / 27)

		@media (max-width $grid-md)
			font-size 2rem
			line-height (23 / 20)

		&:hover
			color $white
			opacity .8

		&:before
			position absolute
			top 50%
			right -55px
			width 25px
			height 25px
			background-image url('../images/media/media__link.svg')
			background-position center
			background-size 100%
			background-repeat no-repeat
			content ''
			transform translateY(-50%)

			@media (max-width $grid-md)
				right -30px
				width 20px
				height 20px