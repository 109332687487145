.form
	&-group
		position relative

		&:before
			position absolute
			top 50%
			right 3.5rem
			width 3rem
			height 3rem
			background-position center
			background-size 100% auto
			background-repeat no-repeat
			content ''
			transform translateY(-50%)

			@media (max-width $grid-md)
				top 40%
				right 15px

		&-name
			&:before
				background-image url('../images/form/form-name.svg')

		&-mail
			&:before
				background-image url('../images/form/form-mail.svg')

		&-phone
			&:before
				background-image url('../images/form/form-phone.svg')

		&-pay
			&:before
				background-image url('../images/form/form-pay.svg')

				@media (max-width $grid-md)
					top 25%

		&-message
			&:before
				top 35%
				background-image url('../images/form/form-message.svg')

				@media (max-width $grid-md)
					top 25%

		label
			position absolute
			top 2rem
			left 5rem
			color $gray-800
			text-transform uppercase
			font-weight bold
			font-size 2.3rem
			line-height (27 / 23)

			@media (max-width $grid-md)
				top 25px
				left 20px
				font-size 2rem
				line-height (23 / 29)

	&-control
		padding-top 3rem
		padding-left 5rem
		min-height 9rem
		border 0
		border-radius 0
		background-color #E8E8E8
		color $gray-800
		font-weight 300
		font-size 1.75rem
		line-height (27 / 17)
		@extend .transition

		&:focus
			outline none
			border 0
			background-color darken(#E8E8E8, 20%)
			box-shadow none

		@media (max-width $grid-md)
			padding-top 40px
			padding-left 20px
			min-height 80px
			font-size 1.7rem

		&-textarea
			padding-top 4.5rem

			@media (max-width $grid-md)
				padding-top 50px
				padding-right 30px