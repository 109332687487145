.article
	padding-top 70px
	padding-bottom 100px

	@media (max-width $grid-md)
		padding-top 0
		padding-bottom 35px

	&__photo
		margin-bottom 3.5rem
		padding-top (570 * 100 / 905)%
		background-size cover

		@media (max-width $grid-md)
			margin-right -15px
			margin-bottom 10px
			margin-left -15px
			padding-top (360 * 100 / 360)%

	&__type
		display flex
		justify-content center
		align-items center
		width 255px
		height 50px
		background-color $primary
		color $white
		text-transform uppercase
		font-size 1.9rem
		line-height (36 / 19)

		@media (max-width $grid-lg)
			width 130px

		@media (max-width $grid-md)
			width 75px
			height 25px
			font-size 1.2rem

	&__info
		position relative
		font-weight 300
		font-size 1.5rem
		line-height (20 / 15)

		@media (max-width $grid-md)
			font-size 1rem
			line-height (20 / 10)

		&:before
			position absolute
			top 50%
			left -35px
			background-size 100% 100%
			background-repeat no-repeat
			content ''
			transform translateY(-50%)

			@media (max-width $grid-md)
				top 47%
				left -13px

		&-view
			margin-right 95px

			@media (max-width $grid-lg)
				margin-right 60px

			@media (max-width $grid-md)
				margin-right 25px

			&:before
				left -45px
				width 38px
				height 18px
				background-image url('../images/card/card-view.svg')

				@media (max-width $grid-md)
					left -18px
					width 18px
					height 8px

		&-date
			&:before
				width 25px
				height 25px
				background-image url('../images/card/card-data.svg')

				@media (max-width $grid-md)
					width 11px
					height 12px