.burger
	width 30px
	height 20px
	transition .5s ease-in-out
	transform rotate(0deg)

	span
		position absolute
		left 0
		display block
		width 100%
		height 3px
		background-color $primary
		opacity 1
		transition .25s ease-in-out
		transform rotate(0deg)

	span:nth-child(1)
		top 0px

	span:nth-child(2),
	span:nth-child(3)
		top 50%

	span:nth-child(4)
		top 100%

	&-show span:nth-child(1)
		top 50%
		left 50%
		width 0%

	&-show span:nth-child(2)
		transform rotate(45deg)

	&-show span:nth-child(3)
		transform rotate(-45deg)

	&-show span:nth-child(4)
		top 50%
		left 50%
		width 0%

	&-white
		span
			background-color $white
