.footer
	padding-bottom 3.5rem
	border-bottom 9px solid $primary

	@media (max-width $grid-md)
		padding-bottom 15px

	&__logo
		display block
		max-width 100%
		width 22.5rem
		height 11rem
		background-image url('../images/header/header__logo.svg')
		background-position 50%
		background-size 100%
		background-repeat no-repeat

		@media (max-width $grid-md)
			width 165px
			height 45px