.content
	color $gray-800
	font-size 2rem
	line-height (27 / 20)

	p
		margin 0
		margin-bottom 3rem

		@media (max-width $grid-md)
			margin-bottom 2.5rem

	@media (max-width $grid-md)
		font-size 1.7rem
		line-height (27 / 17)

	&-article
		margin-bottom 110px
		line-height (33 / 20)

		@media (max-width $grid-md)
			margin-bottom 40px
			font-size 1.8rem
			line-height (25 / 18)