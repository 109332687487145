.quest
	position relative
	padding 20px 140px 55px 140px
	border-radius 15px
	background-image url('../images/quest/quest-background.png')
	background-position 24% 100%
	background-size 45%
	background-repeat no-repeat
	box-shadow 0px 4px 15px alpha($black, .15)

	@media (max-width $grid-xl)
		padding 20px 70px 30px 70px

	@media (max-width $grid-lg)
		padding 20px 30px 30px

	@media (max-width $grid-md)
		margin 0
		padding 35px 15px 0
		border-radius 0
		background-position 100% 50%
		background-size 100%

	@media (max-width 575px)
		margin-right -15px
		margin-left -15px
		background-position 100% 55%

	&:before
		position absolute
		top 0
		right 0
		bottom 0
		left 0
		border-radius 15px
		background-color alpha($gray-200, .4)
		content ''

		@media (max-width $grid-md)
			border-radius 0

	&__title
		font-size 5rem
		line-height (59 / 50)

	&__info
		text-transform uppercase
		font-size 2rem
		line-height (23 / 20)

	&__list
		margin 0
		padding-left 20px
		list-style none
		font-weight 300
		font-size 1.8rem
		line-height (27 / 18)

		li
			position relative
			margin-bottom 7px

			@media (max-width $grid-md)
				margin-bottom 5px

			&:before
				position absolute
				top 50%
				left -20px
				width 9px
				height 9px
				border-radius 50%
				background-color $primary
				content ''
				transform translateY(-50%)

	&__card
		margin-bottom 10px
		padding 25px
		padding-bottom 75px
		border-radius 33px
		background #B5A898
		background-image url('../images/quest/quest-card.png')
		background-position 130% 93%
		background-size 56%
		background-repeat no-repeat

		@media (max-width $grid-xl)
			background-position 120% 95%
			background-size 50%

		@media (max-width $grid-lg)
			background-position 120% 9%
			background-size 50%

		@media (max-width $grid-md)
			margin-top 50px
			margin-bottom 0
			padding 45px 15px
			border-radius 0
			background-position 116% 9%
			background-size 45%

	&__plus
		position relative
		padding-left 20px
		font-size 2.2rem
		line-height (26 / 22)

		&:before,
		&:after
			position absolute
			top 15px
			left 0
			width 10px
			height 2px
			background-color $white
			content ''

		&:after
			transform rotate(90deg)

	&__total
		text-transform uppercase
		font-size 2.2rem
		line-height (26 / 22)

		@media (max-width $grid-md)
			font-size 1.4rem
			line-height (16 / 14)

	&__number
		font-size 2.6rem
		line-height (30 / 26)

	&__progress
		position relative
		width 100%
		height 35px
		background-color $white

	&__fill
		position absolute
		top 0
		bottom 0
		left 0
		width 10%
		background-color $primary

	&__question
		font-weight bold
		font-size 2.6rem
		line-height (30 / 26)

		@media (max-width $grid-md)
			font-size 2rem
			line-height (23 / 20)

	&__control
		z-index -1
		display none
		opacity 0

		&:checked + .quest__checkbox:after
			display block

	&__checkbox
		position relative
		margin-bottom 18px
		padding-left 55px
		font-weight 300
		font-size 2.5rem
		line-height (29 / 25)

		@media (max-width $grid-md)
			font-size 2rem
			line-height (23 / 20)

		&:before,
		&:after
			position absolute
			top 50%
			content ''
			transform translateY(-50%)

		&:before
			left 0
			width 38px
			height 38px
			border 2px solid #B7B7B7
			border-radius 5px
			background-color #DBDBDB

			@media (max-width $grid-md)
				width 30px
				height 30px

		&:after
			left 5px
			display none
			width 28px
			height 18px
			background-image url('../images/profit/profit__check.svg')
			background-size 28px 18px
			background-size 100% 100%
			background-repeat no-repeat

			@media (max-width $grid-md)
				width 19px
				height 14px

		&-light
			@media (max-width $grid-md)
				padding-left 45px
				font-size 1.8rem

			&:before
				border 1px solid #828282
				background-color $white

	&__wrapper
		padding 90px 0

		@media (max-width $grid-md)
			padding 0

		&-form
			padding 60px 0

			@media (max-width $grid-md)
				padding 0

	.title-h2
		@media (max-width $grid-md)
			font-size 3.2rem

	.title-h5
		@media (max-width $grid-md)
			font-size 2rem
			line-height (23 / 20)

	&__input
		padding-left 15px
		max-width 100%
		width 435px
		height 75px
		border .5px solid #828282
		background-color $white
		color #7C7C7C
		font-size 1.7rem
		line-height (20 / 17)

		@media (max-width $grid-md)
			width 100%
			height 55px

		&:focus
			outline none

	&__group
		position relative
		max-width 100%
		width max-content

		&:before
			position absolute
			top 50%
			right 25px
			width 30px
			height 30px
			background-size 100% 100%
			background-repeat no-repeat
			content ''
			transform translateY(-50%)

		&-name
			@media (max-width $grid-md)
				width 100%

			&:before
				background-image url('../images/form/form-name.svg')

		&-phone
			@media (max-width $grid-md)
				width 100%

			&:before
				background-image url('../images/form/form-phone.svg')