.timeline
	position relative
	display grid
	counter-reset section
	grid-gap 0 30rem
	grid-template-columns 1fr 1fr
	grid-template-areas '.  item1' 'item2 .' '. item3' 'item4 .' '. item5' 'item6 .' '. item7' 'item8 .'

	@media (max-width $grid-md)
		margin-top 130px
		padding-left 85px
		grid-template-columns 100%
		grid-template-areas 'item1 .' 'item2 .' 'item3 .' 'item4 .' 'item5 .' 'item6 .' 'item7 .' 'item8 .'

		br
			display none

	&:before
		position absolute
		top 15px
		bottom 120px
		left 50%
		width 1px
		background-color alpha($primary, .5)
		content ''
		transform translateX(-50%)

		@media (max-width $grid-md)
			top -20px
			bottom 10%
			left 30px
			transform none

	&__item
		position relative
		display flex
		flex-direction column
		height 18rem

		@media (max-width $grid-lg)
			margin-bottom 50px
			height max-content

		@media (max-width $grid-lg)
			margin-bottom 100px

		&:last-child
			@media (max-width $grid-lg)
				margin-bottom 35px

		&:before
			position absolute
			top 10px
			display flex
			justify-content center
			align-items center
			width 60px
			height 60px
			border-radius 50%
			background-color $primary
			color $white
			content counter(section)
			counter-increment section
			font-weight bold
			font-size 3rem
			line-height (35 / 30)

			@media (max-width $grid-md)
				top -65px

		&:nth-of-type(odd)
			&:before
				left -18rem

				@media (max-width $grid-xl)
					left -18.8rem

				@media (max-width $grid-lg)
					left -150px

				@media (max-width $grid-md)
					left -85px

		&:nth-of-type(even)
			&:before
				right -18rem

				@media (max-width $grid-xl)
					right -18.8rem

				@media (max-width $grid-lg)
					right -150px

				@media (max-width $grid-md)
					right auto
					left -85px

		&:nth-child(2n)
			align-items flex-end
			text-align right

			@media (max-width $grid-md)
				align-items flex-start
				text-align left

		&:after
			position absolute
			left 0
			background-size 100% 100%
			background-repeat no-repeat
			content ''

		&:nth-of-type(even)
			&:after
				right 0
				left auto

				@media (max-width $grid-md)
					right auto
					left 0

		.content
			width 90%

			@media (max-width $grid-lg)
				width 96%

			@media (max-width $grid-md)
				width 90%

		&-1
			grid-area item1

			&:after
				top -100px
				width 85px
				height 85px
				background-image url('../images/timeline/timeline-1.svg')

				@media (max-width $grid-md)
					top -70px
					width 60px
					height 60px

		&-2
			grid-area item2

			&:after
				top -115px
				width 115px
				height 100px
				background-image url('../images/timeline/timeline-2.svg')

				@media (max-width $grid-md)
					top -75px
					width 75px
					height 65px

		&-3
			grid-area item3

			&:after
				top -100px
				width 70px
				height 85px
				background-image url('../images/timeline/timeline-3.svg')

				@media (max-width $grid-md)
					top -65px
					width 45px
					height 55px

		&-4
			grid-area item4

			&:after
				top -140px
				width 100px
				height 125px
				background-image url('../images/timeline/timeline-4.svg')

				@media (max-width $grid-md)
					top -90px
					width 75px
					height 75px

		&-5
			grid-area item5

			&:after
				top -115px
				width 105px
				height 100px
				background-image url('../images/timeline/timeline-5.svg')

				@media (max-width $grid-md)
					top -70px
					width 55px
					height 55px

		&-6
			grid-area item6

			&:after
				top -105px
				width 90px
				height 90px
				background-image url('../images/timeline/timeline-6.svg')

				@media (max-width $grid-md)
					top -75px
					width 65px
					height 65px

		&-7
			grid-area item7

			&:after
				top -130px
				width 115px
				height 115px
				background-image url('../images/timeline/timeline-7.svg')

				@media (max-width $grid-md)
					top -75px
					width 55px
					height 65px

		&-8
			grid-area item8

			&:after
				top -110px
				width 80px
				height 95px
				background-image url('../images/timeline/timeline-8.svg')

				@media (max-width $grid-md)
					top -85px
					width 55px
					height 70px

	.title
		@media (max-width $grid-md)
			font-size 2rem
			line-height (23 / 20)

	.content
		@media (max-width $grid-md)
			font-size 1.8rem
			line-height (22 / 18)