.about
	&__background
		padding-top 395px
		background-position center
		background-size auto 100%
		background-repeat no-repeat

		@media (max-width $grid-md)
			padding-top 430px
			background-position 25% 0%
			background-size 240% auto
			background-repeat no-repeat

	&__content
		padding 60px
		width 665px
		border-bottom 12px solid $primary
		background-color $white

		@media (max-width $grid-md)
			padding 40px 15px 30px
			width 100%
			border-width 7px

	&__title
		color $primary
		font-weight bold
		font-size 5rem
		line-height (55 / 50)

		@media (max-width $grid-md)
			font-size 3.2rem
			line-height (40 / 32)

	&__text
		font-size 2rem
		line-height (35 / 20)

		@media (max-width $grid-md)
			font-size 1.8rem
			line-height (30 / 18)

	&__photo
		padding-top (869 * 100 / 585)%
		background-position center
		background-size cover

		@media (max-width $grid-md)
			padding-top (410 * 100 / 360)%

	&__item
		position relative
		margin-bottom 5rem
		padding-right 70px
		padding-left 140px

		@media (max-width $grid-md)
			padding-right 0
			padding-left 80px

		&:before
			position absolute
			top 0
			left 0
			background-size 100% 100%
			background-repeat no-repeat
			content ''

			@media (max-width $grid-md)
				left 15px

		&-open
			&:before
				width 75px
				height 75px
				background-image url('../images/about/about-open.svg')

				@media (max-width $grid-md)
					width 50px
					height 50px

		&-avail
			&:before
				width 90px
				height 65px
				background-image url('../images/about/about-avail.svg')

				@media (max-width $grid-md)
					width 55px
					height 40px

		&-reputation
			&:before
				width 85px
				height 85px
				background-image url('../images/about/about-reputation.svg')

				@media (max-width $grid-md)
					width 55px
					height 55px

		&-work
			&:before
				width 90px
				height 95px
				background-image url('../images/about/about-work.svg')

				@media (max-width $grid-md)
					width 60px
					height 60px

		.title
			@media (max-width $grid-md)
				font-size 2.7rem
				line-height (30 / 27)

		.content
			@media (max-width $grid-md)
				font-size 2rem
				line-height (27 / 20)
