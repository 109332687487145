.profit
	&__photo
		padding-top (660 * 100 / 680)%
		background-position center
		background-size 100%
		background-repeat no-repeat

	&__item
		position relative
		padding-left 70px

		&:before
			position absolute
			top 0
			left 0
			width 45px
			height 45px
			border 1px solid $gray-600
			border-radius 50%
			background-image url('../images/profit/profit__check.svg')
			background-position center
			background-size 25px 20px
			background-repeat no-repeat
			content ''

		&-large
			padding-left 80px

			@media (max-width $grid-md)
				padding-left 55px

			&:before
				width 55px
				height 55px
				background-size 30px 25px

				@media (max-width $grid-md)
					width 45px
					height 45px
					background-size 25px 20px

	&__image
		display block
		flex-shrink 0
		margin-right 75px
		width 52%

		@media (max-width $grid-lg)
			margin 55px -30px 0 -15px
			width 175%

	&__document
		display block
		margin-top -21.5rem
		width 33.5rem

		img
			max-width 100%
			width 100%

		@media (max-width $grid-lg)
			margin 20px auto 10px
			width 60%