.btn
	position relative
	display flex
	justify-content center
	align-items center
	max-width 100%
	width 30rem
	height 6.5rem
	border-radius 0
	text-transform uppercase
	font-size 2rem
	line-height (36 / 20)
	@extend .transition

	@media (max-width $grid-md)
		width 200px
		height 35px
		font-size 1.5rem

	&-primary
		border-color $primary
		background-color $primary
		color $white

		&:hover,
		&:focus,
		&:active
			border-color $primaryHov !important
			background-color $primaryHov !important
			box-shadow none
			color $white

	&-outline
		border 1px solid
		color $primary

		&:hover,
		&:focus,
		&:active
			border-color $primary !important
			background-color $primary !important
			box-shadow none
			color $white

	&-consultation
		height 75px

		@media (max-width $grid-md)
			height 45px

	&-form
		width 32rem

		@media (max-width $grid-lg)
			min-width 100%
			width 100%

		@media (max-width $grid-md)
			margin-top 35px
			height 75px
			font-size 1.9rem

	&-pay
		height 7.5rem
		font-size 1.9rem

		&:after
			display block
			margin-left 5px
			width 15px
			height 15px
			border-right 2px solid $white
			border-bottom 2px solid $white
			border-radius 3px
			content ''
			transform rotate(-45deg)

	&-quest
		padding 0 60px
		width auto
		height 5.5rem
		font-size 1.9rem

		@media (max-width $grid-md)
			height 50px

		&:after
			display block
			margin-left 5px
			width 15px
			height 15px
			border-right 2px solid $white
			border-bottom 2px solid $white
			border-radius 3px
			content ''
			transform rotate(-45deg)

	&-back
		padding 0 60px
		width auto
		height 5.5rem
		font-size 1.9rem

		@media (max-width $grid-md)
			height 50px

		&:hover
			&:before
				border-color $white

		&:before
			display block
			margin-right 5px
			width 15px
			height 15px
			border-right 2px solid $primary
			border-bottom 2px solid $primary
			border-radius 3px
			content ''
			transform rotate(135deg)
			@extend .transition

	&-blog
		position relative
		width 475px
		height 55px
		font-size 1.9rem

		@media (max-width $grid-md)
			width 100%
			height 45px
			font-size 1.6rem

		&:after
			position absolute
			top 40%
			right 135px
			display block
			width 15px
			height 15px
			border-right 2px solid $white
			border-bottom 2px solid $white
			border-radius 3px
			content ''
			transform translateY(-50%) rotate(45deg)

			@media (max-width $grid-md)
				right 23%

	&-prev,
	&-next
		width 40%
		height 55px
		font-size 1.9rem

		@media (max-width $grid-lg)
			width 45%

		@media (max-width $grid-md)
			width 140px
			height 30px
			font-size 1.4rem

	&-prev
		&:before
			display block
			margin-right 5px
			width 15px
			height 15px
			border-right 2px solid $white
			border-bottom 2px solid $white
			border-radius 3px
			content ''
			transform rotate(135deg)

			@media (max-width $grid-md)
				width 10px
				height 10px

	&-next
		&:after
			display block
			margin-left 5px
			width 15px
			height 15px
			border-right 2px solid $white
			border-bottom 2px solid $white
			border-radius 3px
			content ''
			transform rotate(-45deg)

			@media (max-width $grid-md)
				width 10px
				height 10px
