.contact
	&__icon
		flex-shrink 0
		margin-right 4rem
		width 10.5rem
		height 10.5rem
		border-radius 50%
		background-color #3F3F3F
		background-position center
		background-repeat no-repeat

		@media (max-width $grid-md)
			margin 25px auto 15px

		&-phone
			background-image url('../images/contact/contact-phone.svg')
			background-size 5.5rem

		&-mail
			background-image url('../images/contact/contact-mail.svg')
			background-size 5.5rem 3.5rem

		&-address
			background-image url('../images/contact/contact-address.svg')
			background-size 5rem 6rem

	&__link
		display inline-block
		color $white
		font-weight bold
		font-size 2.7rem
		line-height (32 / 27)

		@media (max-width $grid-xl)
			font-size 2.3rem

		@media (max-width $grid-md)
			font-size 2.2rem

	&__content
		color $white
		font-weight 300
		font-size 2rem
		line-height (27 / 20)

		@media (max-width $grid-xl)
			font-size 1.5rem

		@media (max-width $grid-md)
			font-size 2rem
			line-height (27 / 20)

	&__text
		color $white
		text-transform uppercase
		font-size 2.3rem
		line-height (27 / 23)

		@media (max-width $grid-xl)
			font-size 1.8rem

		@media (max-width $grid-md)
			font-size 2.2rem

	&__phone
		width 3.5rem
		height 3.5rem
		background-position center
		background-size 100%
		background-repeat no-repeat

		@media (max-width $grid-md)
			margin 0 10px

		&-viber
			background-image url('../images/contact/contact-viber.svg')

		&-whatsapp
			background-image url('../images/contact/contact-whatsapp.svg')

	&__item
		display flex
		justify-content flex-start
		align-items center
		margin-bottom 65px

		@media (max-width $grid-xl)
			margin-bottom 50px

		@media (max-width $grid-md)
			flex-direction column
			flex-wrap wrap
			justify-content center
			margin-bottom 15px