.collapse
	&__wrapper
		position relative
		margin-bottom 50px
		padding 0 75px 0 165px
		border .5px solid $primary

		@media (max-width $grid-md)
			margin-bottom 15px
			padding 0 20px 0 50px

		&:before,
		&:after
			position absolute
			top 50px
			left 80px
			width 33px
			height 7px
			background-color $primary
			content ''
			@extend .transition

			@media (max-width $grid-md)
				top 25px
				left 20px
				width 18px
				height 4px

		&:after
			transform rotate(90deg)

		&-open
			border-radius 15px
			box-shadow 0px 4px 15px alpha($black, .15)

			&:after
				transform none

	&__title
		padding 40px 0
		text-transform uppercase
		font-size 2.6rem
		line-height (30 / 26)

		@media (max-width $grid-md)
			padding 15px 0
			font-size 2rem
			line-height (23 / 20)

	&__content
		display none
		margin-bottom 70px
		font-weight 300
		font-size 2rem
		line-height (31 / 20)

		@media (max-width $grid-md)
			margin-bottom 50px
			font-size 1.8rem
			line-height (25 / 18)