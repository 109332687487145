.social
	&__link
		display block
		flex-shrink 0
		margin 0 1rem
		width 7rem
		height 7rem
		border 1px solid alpha($primary, .2)
		background-position center
		background-repeat no-repeat

		@media (max-width $grid-md)
			width 60px
			height 60px

		&-odn
			background-image url('../images/social/social-odn.svg')
			background-size 20px 30px

		&-vk
			background-image url('../images/social/social-vk.svg')
			background-size 30px 20px

		&-viber
			background-image url('../images/social/social-viber.svg')
			background-size 30px 30px

		&-whatsapp
			background-image url('../images/social/social-whatsapp.svg')
			background-size 35px 35px