$svg-common 
	background url("../images/icons.svg") no-repeat

.icons__icon-name
	@extend $svg-common
	background-position 0 0
	width 129px
	height 129px

.icons__icon-phone
	@extend $svg-common
	background-position 100% 0
	width 127px
	height 127px

