.menu
	display flex
	justify-content space-between
	align-items center
	width 78%

	@media (max-width $grid-lg)
		display none

		&-show
			position fixed
			top 60px
			right 0
			bottom 0
			left 0
			z-index 100
			display block
			overflow-y scroll
			padding 0 15px
			width 100%
			background-color $white