.consultation
	&__input
		padding-left 70px
		width 100%
		height 75px
		border 0
		border-radius 0
		color $gray-600
		font-size 1.7rem
		line-height (20 / 17)

		@media (max-width $grid-md)
			padding-left 55px
			height 50px
			font-size 1.5rem
			line-height (18 / 15)

	&__group
		position relative
		width 42%

		@media (max-width $grid-xl)
			width 45%

		@media (max-width $grid-md)
			margin-right 0
			width 100%

		&:before
			position absolute
			top 50%
			left 25px
			width 27px
			height 27px
			background-position center
			background-size 100%
			background-repeat no-repeat
			content ''
			transform translateY(-50%)

			@media (max-width $grid-md)
				left 20px
				width 20px
				height 20px

		&-name
			margin-right 90px

			@media (max-width $grid-xl)
				margin-right 45px

			@media (max-width $grid-md)
				margin-right 0

			&:before
				background-image url('../images/input/input-name.svg')

		&-phone
			&:before
				left 35px
				background-image url('../images/input/input-phone.svg')
