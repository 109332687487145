.modal
	position fixed
	top 0
	right 0
	bottom 0
	left 0
	z-index -1
	visibility hidden
	overflow-y auto
	transition-delay .3s

	&-show
		z-index 250
		display block
		visibility visible
		transition .3s all ease

		.modal__overlay
			opacity 1

		.modal__block
			border-radius 5px
			opacity 1
			transition-delay .3s

	&__overlay
		position fixed
		top 0
		right 0
		bottom 0
		left 0
		z-index 154
		background-color alpha($black, .5)
		opacity 0
		transition opacity .3s ease
		transform translateZ(-1000px)

	&__block
		position absolute
		top 10%
		left 50%
		z-index 155
		padding 60px 50px 95px 70px
		max-width 100%
		width 610px
		border-radius 15px
		background-color $white
		box-shadow 0px 4px 15px alpha($black, .15)
		opacity 0
		transition all .3s ease
		transform translate(-50%, 0)

		@media (max-width $grid-md)
			padding 50px 15px 30px

		&-relative
			position relative
			top 0
			left 0
			z-index 1
			margin-bottom -80px
			opacity 1
			transform none

			@media (max-width $grid-lg)
				padding 45px 25px 65px 30px

			@media (max-width $grid-md)
				margin-top 60px
				padding 50px 15px 60px 15px
				width 100%
				border-radius 0
				box-shadow none

			.title
				@media (max-width $grid-md)
					text-align center

					br
						display none

		&-pay
			margin-bottom 0
			padding-top 50px
			padding-bottom 55px

			@media (max-width $grid-lg)
				overflow-x hidden
				margin-top 0 !important
				padding 20px 15px 60px 15px
				width 100%
				border-radius 0
				box-shadow none

			&:before
				position absolute
				top 4%
				left -147%
				width 186%
				height 100%
				background-image url('../images/background/background-pay.png')
				background-position center
				background-size 100%
				background-repeat no-repeat
				content ''

				@media (max-width $grid-xl)
					left -115%
					width 150%

				@media (max-width $grid-lg)
					top 12%
					left 65%
					width 480px
					height 235px
					transform translateX(-50%)

			.title
				margin-bottom 5rem

				@media (max-width $grid-lg)
					margin-bottom 295px
					text-align center

	&__close
		position absolute
		top 10px
		right -50px
		width 20px
		height 20px
		opacity .75
		transition .3s opacity ease

		&:before,
		&:after
			position absolute
			top 50%
			left 50%
			display block
			width 28px
			height 2px
			border-radius 2px
			background-color $white
			content ''
			transform translate(-50%, -50%) rotate(45deg)
			transform-origin center center

		&:after
			transform translate(-50%, -50%) rotate(-45deg)

		@media (max-width $grid-sm)
			top -50px
			right 40px

		&:hover
			opacity 1

	&-video-close-btn
		top 20px !important
		right 30px !important
		margin-top 0 !important
		width 20px !important
		height 20px !important

		&:before,
		&:after
			background $primary !important
