.banner
	position relative
	overflow hidden
	padding-top 120px

	@media (max-width $grid-lg)
		padding-top 0

	&__content
		position relative
		padding-bottom 110px

		&:before
			position absolute
			bottom -340px
			left 80%
			width 830px
			height 1030px
			background-image url('../images/banner/banner__bg.png')
			background-position bottom
			background-size 100%
			background-repeat no-repeat
			content ''
			transform translateX(-50%)

			@media (max-width $grid-xl)
				bottom -140px
				width 465px
				height 580px

			@media (max-width $grid-lg)
				bottom -85px
				left 50%
				width 320px
				height 480px

			@media (max-width $grid-md)
				bottom -100px

		@media (max-width $grid-lg)
			padding-bottom 300px

		@media (max-width $grid-md)
			.btn
				flex-shrink 0
				margin-right 50%

	&__text
		margin-bottom 7rem
		font-size 2.5rem
		line-height (36 / 25)

		@media (max-width $grid-xl)
			br
				display none

		@media (max-width $grid-md)
			margin-bottom 1.5rem
			font-size 1.6rem
			line-height (20 / 16)

	&__info
		position absolute
		right 25px
		bottom 15px
		left 0

		@media (max-width $grid-lg)
			right -140px

	&__name
		color $primary
		font-weight 500
		font-size 2.6rem
		line-height (30 / 26)

		@media (max-width $grid-md)
			font-size 1.8rem
			line-height (17 / 18)

	&__post
		font-weight 300
		font-size 1.7rem
		line-height (36 / 17)

		@media (max-width $grid-md)
			font-size 1.5rem
			line-height (10 / 15)