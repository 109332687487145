.submenu
	@media (max-width $grid-lg)
		width 100%

	&:hover
		.submenu__wrapper
			display block

	&__wrapper
		position absolute
		top 80px
		left 30px
		display none
		border-bottom 2px solid $primary
		background-color $white
		box-shadow 0px 4px 15px alpha($black, .15)
		@extend .transition

		@media (max-width $grid-xl)
			top 60px
			left 12px

		@media (max-width $grid-lg)
			position relative
			top 0
			left 0
			display block
			margin-top 5px
			border 0
			box-shadow none

	&__link
		display flex
		align-items center
		padding-left 20px
		width 180px
		height 35px
		border-bottom .5px solid #D6D6D6
		color $black
		text-transform uppercase
		font-size 1.2rem
		line-height (14 / 12)

		&:hover
			color alpha($black, .8)

		@media (max-width $grid-lg)
			padding-left 10px
			width 100%
			height 40px
			border 0
			font-size 1.8rem
			line-height (21 / 18)
