.transition
	transition all .3s ease

html,
body
	overflow-x hidden
	-webkit-font-smoothing antialiased
	text-rendering optimizeLegibility !important

html
	font-size 10px

	@media (max-width $grid-xl)
		font-size 8px

	@media (max-width $grid-md)
		font-size 10px

body
	@media (max-width $grid-lg)
		padding-top 60px

a
	transition .2s all ease

	&:hover,
	&:focus
		outline none
		text-decoration none

@font-face
	font-weight normal
	font-style normal
	font-family 'Roboto'
	src url('Roboto-Regular.eot')
	src local('Roboto Regular'), local('Roboto-Regular'), url('../fonts/Roboto-Regular.eot?#iefix') format('embedded-opentype'), url('../fonts/Roboto-Regular.woff') format('woff'), url('../fonts/Roboto-Regular.ttf') format('truetype')

@font-face
	font-weight 500
	font-style normal
	font-family 'Roboto'
	src url('Roboto-Medium.eot')
	src local('Roboto Medium'), local('Roboto-Medium'), url('../fonts/Roboto-Medium.eot?#iefix') format('embedded-opentype'), url('../fonts/Roboto-Medium.woff') format('woff'), url('../fonts/Roboto-Medium.ttf') format('truetype')

@font-face
	font-weight bold
	font-style normal
	font-family 'Roboto'
	src url('Roboto-Bold.eot')
	src local('Roboto Bold'), local('Roboto-Bold'), url('../fonts/Roboto-Bold.eot?#iefix') format('embedded-opentype'), url('../fonts/Roboto-Bold.woff') format('woff'), url('../fonts/Roboto-Bold.ttf') format('truetype')

@font-face
	font-weight bold
	font-style italic
	font-family 'Roboto'
	src url('Roboto-BoldItalic.eot')
	src local('Roboto BoldItalic'), local('Roboto-BoldItalic'), url('../fonts/Roboto-BoldItalic.eot?#iefix') format('embedded-opentype'), url('../fonts/Roboto-BoldItalic.woff') format('woff'), url('../fonts/Roboto-BoldItalic.ttf') format('truetype')

@font-face
	font-weight 700
	font-style normal
	font-family 'Roboto'
	src url('Roboto-Black.eot')
	src local('Roboto Black'), local('Roboto-Black'), url('../fonts/Roboto-Black.eot?#iefix') format('embedded-opentype'), url('../fonts/Roboto-Black.woff') format('woff'), url('../fonts/Roboto-Black.ttf') format('truetype')

@font-face
	font-weight 300
	font-style normal
	font-family 'Roboto'
	src url('Roboto-Light.eot')
	src local('Roboto Light'), local('Roboto-Light'), url('../fonts/Roboto-Light.eot?#iefix') format('embedded-opentype'), url('../fonts/Roboto-Light.woff') format('woff'), url('../fonts/Roboto-Light.ttf') format('truetype')

@font-face
	font-weight 200
	font-style normal
	font-family 'Roboto'
	src url('Roboto-Thin.eot')
	src local('Roboto Thin'), local('Roboto-Thin'), url('../fonts/Roboto-Thin.eot?#iefix') format('embedded-opentype'), url('../fonts/Roboto-Thin.woff') format('woff'), url('../fonts/Roboto-Thin.ttf') format('truetype')

@font-face
	font-weight normal
	font-style italic
	font-family 'Roboto'
	src url('Roboto-Italic.eot')
	src local('Roboto Italic'), local('Roboto-Italic'), url('../fonts/Roboto-Italic.eot?#iefix') format('embedded-opentype'), url('../fonts/Roboto-Italic.woff') format('woff'), url('../fonts/Roboto-Italic.ttf') format('truetype')