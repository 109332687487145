.text
	&-medium
		font-weight 500 !important

	&-bold
		font-weight bold !important

	&-thin
		font-weight 300 !important

	&-uppercase
		text-transform uppercase