.link
	&-white
		border-bottom 1px solid alpha($white, .8)
		color $white

		&:hover
			border-bottom 1px solid $white
			color $white

	&-primary
		border-bottom 1px solid alpha($primary, .8)
		color $primary

		&:hover
			border-bottom 1px solid $primary
			color $primary