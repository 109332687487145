.title
	position relative

	&-h1
		letter-spacing -.015em
		font-weight bold
		font-size 6.3rem
		line-height (74 / 63)

		@media (max-width $grid-md)
			font-size 3rem
			line-height (33 / 30)

	&-h2
		font-weight bold
		font-size 5.7rem
		line-height (67 / 57)

		@media (max-width $grid-md)
			font-size 3rem

	&-h3
		font-weight bold
		font-size 4.8rem
		line-height (56 / 48)

		@media (max-width $grid-md)
			font-size 3rem
			line-height (35 / 30)

	&-h4
		font-weight bold
		font-size 3.2rem
		line-height (37 / 32)

	&-h5
		text-transform uppercase
		font-weight 500
		font-size 2.6rem
		line-height (30 / 26)

		@media (max-width $grid-md)
			font-size 1.8rem
			line-height (24 / 18)

		&-form
			@media (max-width $grid-md)
				font-size 1.6rem
				line-height (28 / 16)

		&-thin
			font-weight 300
			line-height (40 / 26)

	&-md-h5
		@media (max-width $grid-md)
			font-size 2rem
			line-height (23 / 20)

	&-h6
		font-weight bold
		font-size 2.7rem
		line-height (32 / 27)

		@media (max-width $grid-md)
			font-size 2.2rem
			line-height (26 / 22)

	&-md-h6
		@media (max-width $grid-md)
			font-size 2.2rem
			line-height (26 / 22)
