.history
	position relative

	&:before
		position absolute
		top 30px
		bottom 70px
		left 240px
		width 1px
		background-color alpha($primary, .5)
		content ''

		@media (max-width $grid-xl)
			left 194px

		@media (max-width $grid-md)
			bottom 45px
			left 25px

	&:after
		position absolute
		right -9%
		bottom -90px
		z-index 0
		width 81%
		height 100%
		background-image url('../images/quest/quest-background.png')
		background-position bottom
		background-size 100%
		background-repeat no-repeat
		content ''
		opacity .4

		@media (max-width $grid-md)
			right -68%
			bottom -45px
			width 267%

	&__item
		position relative
		z-index 1
		display flex

		@media (max-width $grid-md)
			flex-direction column
			padding-left 50px

		&:before
			position absolute
			top 25px
			left 230px
			width 20px
			height 20px
			border-radius 50%
			background-color $primary
			content ''

			@media (max-width $grid-xl)
				left 185px

			@media (max-width $grid-md)
				top 8px
				left 15px

	&__date
		margin-right 14.5rem

		@media (max-width $grid-md)
			margin-right 0

	&__content
		.title
			@media (max-width $grid-md)
				padding-right 0
				font-size 2rem
				line-height (23 / 20)