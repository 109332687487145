.video
	&__play
		position absolute
		top 44%
		left 50%
		z-index 1
		width 22rem
		height 22rem
		border 1px solid alpha($primary, .5)
		border-radius 50%
		cursor pointer
		transform translate(-50%, -50%)
		@extend .transition

		@media (max-width $grid-md)
			top 43%
			width 120px
			height 120px

		&:hover
			opacity .8

		&:before
			position absolute
			top 50%
			left 50%
			width 16.5rem
			height 16.5rem
			border-radius 50%
			background-color $primary
			content ''
			transform translate(-50%, -50%)

			@media (max-width $grid-md)
				width 90px
				height 90px

		&:after
			position absolute
			top 50%
			left 54%
			width 0
			height 0
			outline none
			border-width 1.8rem 0 1.8rem 2.8rem
			border-style solid
			border-color transparent transparent transparent $white
			content ''
			transform translate(-50%, -50%)

			@media (max-width $grid-md)
				border-width 10px 0 10px 17px