.map
	position relative
	margin-top -15px
	padding-top (770 * 100 / 1365)%
	background-image url('../images/map/map.svg')
	background-position center
	background-size 100%

	@media (max-width $grid-md)
		margin-top 30px

	&__item
		position absolute
		display flex
		justify-content center
		align-items center
		overflow hidden
		width 6rem
		height 6rem
		border 3px solid $primary
		border-radius 50%
		background-color $white
		background-position center
		background-size 120%
		color $primary
		font-weight bold
		font-size 2.7rem

		@media (max-width $grid-md)
			width 20px
			height 20px
			border-width 2px
			font-size 1rem
			line-height (12 / 10)

		&-empty
			@media (max-width $grid-md)
				width 15px
				height 15px

	&__city
		position absolute

	&__title
		font-size 2rem
		line-height (23 / 20)

		@media (max-width $grid-md)
			font-size 1rem
			line-height (12 / 10)

	&__baloon
		padding 15px 45px
		border-radius 5px
		filter drop-shadow(0px 2px 12px rgba(0, 0, 0, .1))
		background-color $white

		@media (max-width $grid-md)
			padding 4px 3px

		&:before
			position absolute
			bottom -34px
			left 50%
			border 15px solid transparent
			border-top 20px solid $white
			content ''
			transform translateX(-50%)

			@media (max-width $grid-md)
				bottom -8px
				border 5px solid transparent
				border-top 3px solid $white

		.title-h6
			@media (max-width $grid-md)
				font-size 1.5rem
				line-height (18 / 15)

	&__point
		width 40px
		height 55px
		background-image url('../images/map/map-point.svg')
		background-size 100% 100%
		background-repeat no-repeat

		@media (max-width $grid-md)
			display none